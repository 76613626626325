(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@pixi/utils"), require("@pixi/math"), require("@pixi/core"), require("@pixi/display"), require("@pixi/graphics"), require("@pixi/text"));
	else if(typeof define === 'function' && define.amd)
		define(["@pixi/utils", "@pixi/math", "@pixi/core", "@pixi/display", "@pixi/graphics", "@pixi/text"], factory);
	else if(typeof exports === 'object')
		exports["PIXI"] = factory(require("@pixi/utils"), require("@pixi/math"), require("@pixi/core"), require("@pixi/display"), require("@pixi/graphics"), require("@pixi/text"));
	else
		root["PIXI"] = root["PIXI"] || {}, root["PIXI"]["live2d"] = factory(root["PIXI"]["utils"], root["PIXI"], root["PIXI"], root["PIXI"], root["PIXI"], root["PIXI"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__5072__, __WEBPACK_EXTERNAL_MODULE__4893__, __WEBPACK_EXTERNAL_MODULE__8704__, __WEBPACK_EXTERNAL_MODULE__8623__, __WEBPACK_EXTERNAL_MODULE__5417__, __WEBPACK_EXTERNAL_MODULE__5888__) => {
return 